<template>
  <section id="hero" style="margin-bottom: 5px;">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/hero.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading title="SOLUCIONES EL MAGO MERLÍN" />

          <base-body>
            <h1>Comprometidos con tu seguridad las 24 horas!</h1>
          </base-body>

          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'flex-row align-start'
                : 'align-center'
            "
            class="d-flex flex-wrap"
          >
            <!-- <base-btn>
              Contactar (WS)
            </base-btn> -->

            <a href="tel:+34669783741" target="_blank" rel="noopener">
              <img src="../../assets/phone.svg" alt="Phone" class="icons spin"
            /></a>

            <a href="https://www.wa.link/lg6sz0" target="_blank" rel="noopener">
              <img
                src="../../assets/whatsapp.svg"
                alt="Whatsapp"
                class="icons"
                target="_blank"
            /></a>

            <a
              href="https://www.instagram.com/elmagomerlincerrajeria/"
              target="_blank"
              rel="noopener"
            >
              <img
                src="../../assets/instagram.svg"
                alt="Instagram"
                class="icons"
                target="_blank"
              />
            </a>

            <a href="https://g.co/kgs/a58TGR" target="_blank" rel="noopener">
              <img
                src="../../assets/googlereviews.png"
                alt="Google reviews"
                class="icons"
                target="_blank"
              />
            </a>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
    <section>
      <!-- <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <h1>SOCIO COMERCIAL</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <a href="https://www.onventanas.com/" target="_blank">
            <v-img
              max-height="400px"
              max-width="400px"
              :src="require('@/assets/onventanas.gif')"
            />
          </a>
        </v-col>
      </v-row> -->
      <!-- <v-row class="d-flex justify-center">
        <v-col cols="11" class="d-flex flex-column align-center justify-center">
          <h2>Onventanas. ventanas de PVC 100% Kömmerling</h2>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="11" class="d-flex justify-center">
          <h3 style="text-align: justify; color:grey">
            Nos ocupamos de fabricar la mejor ventana de PVC para que tu puedas
            olvidarte de las cosas poco importantes como las ventanas. Vive en
            tu hogar en confort, ahorrando energía, aislado de los ruidos, y con
            seguridad. Disfruta de tu hogar. Deja que nosotros nos ocupemos de
            las ventanas de PVC.
          </h3>
        </v-col>
      </v-row> -->
    </section>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true }
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  }
};
</script>

<style scoped>
.icons {
  width: 60px;
  margin-right: 20px;
}
.icons:hover {
  opacity: 0.5;
  transition: 1s;
  cursor: pointer;
}
.spin {
  /* position: absolute;   */
  /* top: 50%;   */
  /* left: 50%;  */
  /* width: 120px;   */
  /* height: 120px;  */
  /* margin:-60px 0 0 -60px;   */
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
